import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import LoadingSpin from "../components/base/Loading";
import router from "../constant/router";

const Home = React.lazy(() => import("./home"));
const Privacypolicy = React.lazy(() => import("./privacypolicy"));
const UserPolicies = React.lazy(() => import("./userPolicies"));

export const RouteContainer = () => (
  <Suspense fallback={<LoadingSpin />}>
    <Routes>
      <Route path={router.home} element={<Home />} />
      <Route path={router.privacypolicy} element={<Privacypolicy />} />
      <Route path={router.userPolicies} element={<UserPolicies />} />
    </Routes>
  </Suspense>
);
